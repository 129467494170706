// utils/schemaHelper.ts
export function getSchemaWithDefaults(schemaData: any, pageContent: any) {
  const defaultSchemas = {
    articleMarkup: getArticleSchema(schemaData.articleFields, pageContent),
    faqMarkup: getFAQSchema(schemaData.faqFields, pageContent),
    jobPostingMarkup: getJobPostingSchema(schemaData.jobPostingFields, pageContent),
    eventMarkup: getEventSchema(schemaData.eventFields, pageContent),
    localBusinessMarkup: getLocalBusinessSchema(schemaData.localBusinessFields, pageContent),
  } as { [key: string]: any };

  return defaultSchemas[schemaData.type] || {};
}

function getArticleSchema(schemaData: any, pageContent: any) {
  return {
    '@context': 'https://schema.org/',
    '@type': 'Article',
    headline: schemaData?.headline || pageContent.title,
    image: schemaData?.image || pageContent.image,
    description: schemaData?.description || pageContent.description,
  };
}

function getFAQSchema(schemaData: any, pageContent: any) {
  return {
    '@context': 'https://schema.org/',
    '@type': 'FAQPage',
    mainEntity: schemaData || pageContent.faq,
  };
}

function getJobPostingSchema(schemaData: any, pageContent: any) {
  return {
    '@context': 'https://schema.org/',
    '@type': 'JobPosting',
    title: schemaData?.title || pageContent.jobTitle,
    description: schemaData?.description || pageContent.jobDescription,
    hiringOrganization: {
      '@type': 'Organization',
      name: 'Møller Bil',
    },
    datePosted: schemaData?.datePosted || pageContent.datePosted,
    validThrough: schemaData?.validThrough || pageContent.validThrough,
    jobLocation: schemaData?.jobLocation || pageContent.jobLocation,
  };
}

function getEventSchema(schemaData: any, pageContent: any) {
  return {
    '@context': 'https://mollerbil.no/',
    '@type': 'Event',
    name: schemaData?.name || pageContent.eventName,
    url: schemaData?.url || pageContent.eventUrl,
  };
}

function getLocalBusinessSchema(schemaData: any, pageContent: any) {
  return {
    '@context': 'https://mollerbil.no/',
    '@type': 'LocalBusiness',
    name: schemaData?.name || pageContent.title,
    url: schemaData?.url || pageContent.slug,
  };
}

const websiteSchemaCode = {
  // automatisk
  '@context': 'https://mollerbil.no/',
  '@type': 'WebSite',
  name: 'Møller Bil',
  url: 'mollerbil.no',
  description:
    'Autorisert bilforhandler av Audi, Volkswagen, og Skoda med landsdekkende service- og verkstedtilbud.',
};

const organizationSchemaCode = (name: string, url: string) => {
  // automatisk
  return {
    '@context': 'https://mollerbil.no/',
    '@type': 'Organization',
    name,
    url,
  };
};

const breadcrumbSchemaCode = (name: string, url: string) => {
  // automatisk
  return {
    '@context': 'https://mollerbil.no/',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        item: {
          '@id': url,
          name,
        },
      },
    ],
  };
};

const eventSchemaCode = (name: string, url: string) => {
  return {
    '@context': 'https://mollerbil.no/',
    '@type': 'Event',
    name,
    url,
  };
};

const localBusinessSchemaCode = (name: string, url: string) => {
  return {
    '@context': 'https://mollerbil.no/',
    '@type': 'LocalBusiness',
    name,
    url,
  };
};

export {
  websiteSchemaCode,
  organizationSchemaCode,
  breadcrumbSchemaCode,
  eventSchemaCode,
  localBusinessSchemaCode,
};

export function getSchemasWithDefaults(schemaArray: any[], pageContent: any) {
  return schemaArray.map((schemaData) => {
    const defaultSchemas = {
      articleMarkup: getArticleSchema(schemaData.articleFields, pageContent),
      faqMarkup: getFAQSchema(schemaData.faqFields, pageContent),
      jobPostingMarkup: getJobPostingSchema(schemaData.jobPostingFields, pageContent),
      eventMarkup: getEventSchema(schemaData.eventFields, pageContent),
      localBusinessMarkup: getLocalBusinessSchema(schemaData.localBusinessFields, pageContent),
    } as { [key: string]: any };

    return defaultSchemas[schemaData.type] || {};
  });
}
